export const hr= {
    "Login": "Login",
    "Please enter your credentials to sign in!": "Please enter your credentials to sign in!",
    "Email": "Email",
    "Password": "Password",
    "Remember Me": " Remember Me",
    "Forgot Password?": "Forgot Password?",
    "Signing in...": "Signing in...",
    "Sign In": "Sign In",
    "Incorrect email or password": "Incorrect email or password",

    "Forgot Password": "Forgot Password",
    "Check your email": "Check your email",
    "We have sent a password recovery instruction to your email": "We have sent a password recovery instruction to your email",
    "Please enter your email address to receive a verification code": "Please enter your email address to receive a verification code",
    "Resend Email": "Resend Email",
    "Send Email": "Send Email",
    "Back to": "Back to",
    "Sign in": "Sign in",
    "Reset done": "Reset done",
    "Your password has been successfully reset": "Your password has been successfully reset",
    "Set new password": "Set new password",
    "Your new password must different to previous password": "Your new password must different to previous password",
    "Confirm Password": "Confirm Password",

    "Submitting ...": "Submitting ...",
    "Submit": "Submit",
    "Continue": "Continue",

    "Inventory": "Inventory",
    "Dashboard": "Dashboard",
    "Filter": "Filter",
    "Pick Confirmed": "Pick Confirmed",
    "Sold": "Sold",
    "Short Items": "Short Items",
    "Fix": "Fix",
    "Status": "Status",
    "Paid": "Paid",
    "Pending": "Pending",
    "Failed": "Failed",
    "Date": "Date",
    "Amount": "Amount",
    "Snapshot Variance Reports": "Snapshot Variance Reports",
    "Unique Items": "Unique Items",
    "Total Item Count": "Total Item Count",
    "Stock Count": "Stock Count",
    "Inbound Items": "Inbound Items",

    "Available": "Available",
    "Low Stock": "Low Stock",
    "Out of Stock": "Out of Stock",
    "Total Stock": "Total Stock",

    "Value Distribution": "Value Distribution",
    "Vertical": "Vertical",
    "Racking": "Racking",
    "Quarantine": "Quarantine",
    "Inspection": "Inspection",

    "Variance Value": "Variance Value",
    "Warehouse Capacity": "Warehouse Capacity",

    "Activities": "Activities",
    "View All": "View All",
    "No Activities": "No Activities",

    "Batch Cycles": "Batch Cycles",
    "Performance": "Performance",
    "Staff": "Staff",

    "Pending Jobs": "Pending Jobs",
    "Completed Jobs": "Completed Jobs",
    "Put away": "Put away",
    "Picks Completed": "Picks Completed",
    "Active Staff": "Active Staff",
    "Work Load": "Work Load",

    "Fulfilment Time (avg)": "Fulfilment Time (avg)",
    "Order": "Order",
    "Total": "Total",
    "Latest Orders": "Latest Orders",
    "View Orders": "View Orders",

    "Categories": "Categories",

    "Orders Dashboard": "Orders Dashboard",
    "View your current sales & summary": "View your current sales & summary",
    "Sales Report": "Sales Report",
    "Export Report": "Export Report",

    "Revenue": "Revenue",
    "Orders": "Orders",
    "Purchases": "Purchases",

    "Processing": "Processing",
    "Return": "Return",
    "Units": "Units",
    "Scan Confirmed": "Scan Confirmed",

    "Top Selling": "Top Selling",
    "View Products": "View Products",
    "Article": "Article",
    "Title": "Title",
    "Quantity": "Quantity",
    "Expired Date": "Expired Date",

    "Product": "Product",
    "Products": "Products",
    "Warehouse": "Warehouse",
    "Zone": "Zone",
    "Lift": "Lift",
    "Lifts": "Lifts",
    "Lift Type": "Lift Type",
    "Lift Types": "Lift Types",
    "Lift Number": "Lift Number",
    "Lift Name": "Lift Name",
    "Shelf": "Shelf",
    "Shelves": "Shelves",
    "User": "User",
    "Users": "Users",
    "User name": "User name",
    "Username": "Username",
    "Access point": "Access point",
    "Barcode": "Barcode",
    "Width": "Width",
    "Height": "Height",
    "Depth": "Depth",
    "Depths": "Depths",
    "Compartment": "Compartment",
    "Compartments": "Compartments",
    "Type": "Type",
    "S/C/D": "S/C/D",
    "Created": "Created",
    "Color": "Color",
    "Count":"Count",
    "Counts":"Counts",
    "Max": "Max",
    "Put": "Put",
    "Pick": "Pick",
    "Location": "Location",
    "Locations": "Locations",
    "Description": "Description",
    "Pick Sheet": "Pick Sheet",
    "Movements": "Movements",
    "Movement": "Movement",
    "Job Name": "Job Name",
    "Required Quantity": "Required Quantity",
    "Actual Quantity": "Actual Quantity",
    "Other": "Other",
    "Bin": "Bin",
    "Bins": "Bins",
    "Setting": "Setting",
    "Settings": "Settings",
    "Max Qty.": "Max Qty.",

    "Filter by dates range": "Filter by dates range",
    "Min Qty.": "Min Qty.",
    "Current Stock": "Current Stock",
    "Transfer": "Transfer",
    "Add Product": "Add Product",

    "No product found!": "No product found!",
    "Add": "Add",
    "Cancel": "Cancel",
    "Save": "Save",
    "Edit": "Edit",
    "Delete": "Delete",
    "Discard": "Discard",

    "Track #": "Track #",
    "Order Date": "Order Date",
    "Shipping Method": "Shipping Method",
    "Supplier": "Supplier",

    "Related Products": "Related Products",
    "Consignment": "Consignment",
    "Consigned": "Consigned",
    "Owner": "Owner",
    "Search Owner": "Search Owner",

    "Scan to pick": "Scan to pick",
    "Active": "Active",
    "Block": "Block",
    "Pick Method": "Pick Method",
    "FIFO": "FIFO",
    "Optimised": "Optimised",
    "Security": "Security",
    "Stock Take": "Stock Take",
    "Stock Valuation": "Stock Valuation",

    "Unit Of Measure": "Unit Of Measure",
    "Print Label on Pick": "Print Label on Pick",
    "Is Serialised": "Is Serialised",

    "Receiving": "Receiving",

    "This field is required": "This field is required",
    "Enter correct url!": "Enter correct url!",
    "Something wrong! Can not upload file": "Something wrong! Can not upload file",
    "Max quantity of the bins are required": "Max quantity of the bins are required",
    "Product successfully added": "Product successfully added",
    "article is existed.": "article is existed.",
    "Error !!!": "Error !!!",
    "Min Quantity": "Min Quantity",
    "UOM": "UOM",
    "Fifo": "Fifo",
    "Pick multiplier": "Pick multiplier",
    "Put away multiplier": "Put away multiplier",
    "Buy Price": "Buy Price",
    "Sell Price": "Sell Price",
    "ERP Code": "ERP Code",
    "Manufacturer": "Manufacturer",
    "Model": "Model",
    "Make": "Make",
    "Is serial numbered": "Is serial numbered",
    "H1": "H1",
    "H2": "H2",
    "H3": "H3",
    "H4": "H4",
    "H5": "H5",
    "H6": "H6",
    "Auto print label": "Auto print label",
    "Scan pick": "Scan pick",
    "Product Image": "Product Image",
    "Please upload a image file!": "Please upload a image file!",
    "Upload image cannot more then {{size}}kb !": "Upload image cannot more then {{size}}kb !",
    "Add or change image for the product": "Add or change image for the product",
    "Drop your image here, or": "Drop your image here, or",
    "browse": "browse",
    "Support: image files": "Support: image files",
    "Use Webcam": "Use Webcam",
    "Capture photo": "Capture photo",
    "Are you sure you want to delete this product? All record related to this product will be deleted as well. This action cannot be undone.": "Are you sure you want to delete this product? All record related to this product will be deleted as well. This action cannot be undone.",
    "Delete product": "Delete product",

    "Counted": "Counted",
    "Completed": "Completed",

    "Category": "Category",
    "Created At": "Created At",
    "Started At": "Started At",
    "Completed At": "Completed At",
    "Item count": "Item count",
    "Value": "Value",
    "Recounted": "Recounted",
    "Variance": "Variance",
    "Approved": "Approved",

    "Checked": "Checked",
    "Checked By": "Checked By",
    "Checked Date": "Checked Date",
    "Comment": "Comment",
    "Sending ...": "Sending ...",
    "Send": "Send",
    "Recount All": "Recount All",
    "Approval All": "Approval All",
    "Matching": "Matching",
    "Recount": "Recount",

    "Count Name": "Count Name",
    "Cycle Count": "Cycle Count",
    "Select the stock take group to count.": "Select the stock take group to count.",
    "In which warehouse": "In which warehouse",
    "Should the articles being counted now be made inactive so they they can not be picked or put until after they have been counted ?": "Should the articles being counted now be made inactive so they they can not be picked or put until after they have been counted ?",
    "Cycle A": "Cycle A",
    "Cycle B": "Cycle B",
    "Cycle C": "Cycle C",
    "No Count": "No Count",

    "All Locations": "All Locations",
    "Shelf Size": "Shelf Size",
    "Lift/Rack Name": "Lift/Rack Name",

    "Bulk": "Bulk",
    "Cross Docking": "Cross Docking",
    "Inspections": "Inspections",
    "Locker": "Locker",
    "Lockers": "Lockers",
    "Are you sure you want to delete this lift? All record related to this lift will be deleted as well. This action cannot be undone.": "Are you sure you want to delete this lift? All record related to this lift will be deleted as well. This action cannot be undone.",
    "Delete lift": "Delete lift",
    "Add Location": "Add Location",
    "Location successfully added": "Location successfully added",
    "The location is existed": "The location is existed",
    "Rack Number": "Rack Number",
    "Rack Name": "Rack Name",
    "Rack Description": "Rack Description",
    "Shelves (S)": "Shelves (S)",
    "Compartments (C)": "Compartments (C)",
    "'Depths (D)": "'Depths (D)",
    "Shelf Width (mm)": "Shelf Width (mm)",
    "Shelf Depth (mm)": "Shelf Depth (mm)",
    "No lift found!": "No lift found!",
    "Required": "Required",

    "Speed": "Speed",
    "Security Groups": "Security Groups",
    "Notes": "Notes",

    "Assign a Shelf map": "Assign a Shelf map",
    "I understand": "I understand",
    "Select the shelf map to assign.": "Select the shelf map to assign.",
    "Actions": "Actions",
    "Shelf Map": "Shelf Map",

    "Add Vertical": "Add Vertical",
    "Edit Vertical": "Edit Vertical",
    "Please Select": "Please Select",
    "Lift Group": "Lift Group",
    "Lock Timer (mins)": "Lock Timer (mins)",
    "Number Of Shelves": "Number Of Shelves",
    "Shelf Width": "Shelf Width",
    "Shelf Depth": "Shelf Depth",
    "Serial Number": "Serial Number",
    "License Key": "License Key",
    "Login Required": "Login Required",
    "Manage Articles": "Manage Articles",
    "Auto Generated Article Codes": "Auto Generated Article Codes",
    "Use Security": "Use Security",
    "Vertical successfully added. Restart the mpx VLM Services to activate the new lift": "Vertical successfully added. Restart the mpx VLM Services to activate the new lift",
    "Generic Error": "Generic Error",
    "Access Point": "Access Point",
    "Language": "Language",
    "Controller PC IP address": "Controller PC IP address",
    "Label Printer": "Label Printer",
    "Printer": "Printer",
    "Article Label Format": "Article Label Format",
    "Question 1": "Question 1",
    "Question 2": "Question 2",
    "Allow Picking": "Allow Picking",
    "Allow Putting": "Allow Putting",
    "Allow Searching": "Allow Searching",
    "Allow Get Shelf": "Allow Get Shelf",
    "Reverse Shelf Map": "Reverse Shelf Map",
    "Store After Pick": "Store After Pick",
    "Store After Job": "Store After Job",
    "Manage Lift": "Manage Lift",
    "Manage Racking": "Manage Racking",
    "Manage Lockers": "Manage Lockers",
    "Enable Camera": "Enable Camera",
    "Left Camera": "Left Camera",
    "Right Camera": "Right Camera",
    "Images storage": "Images storage",
    "Select images storage": "Select images storage",
    "Local Storage": "Local Storage",
    "AWS S3": "AWS S3",
    "Local Path": "Local Path",
    "AWS Bucket Name": "AWS Bucket Name",
    "AWS Dir Name": "AWS Dir Name",
    "AWS Region": "AWS Region",
    "AWS Access Key Id": "AWS Access Key Id",
    "AWS Secret Access Key": "AWS Secret Access Key"

    // TODO : orders, pages, resources, shared, components (table, pagination, ...)
}
