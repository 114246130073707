import { Alert, toast } from '../ui'
import React from 'react'

export default function alertToast({ message, type, showIcon = true, closable = true, duration = 5000 }) {
  toast.push(
    <Alert showIcon={showIcon} closable={closable} type={type} rounded={false} duration={duration}>
      {message}
    </Alert>,
    {
      offsetX: 0,
      offsetY: 0,
      transitionType: 'fade',
      block: true
    }
  )
}
