import { QueryClient } from 'react-query'
import alertToast from '../components/shared/AlertToast'
import React from 'react'

const defaultResponse = {
  onError: (error, variables, context) => {
    const message =
      error.response && error.response.data
        ? Object.keys(error.response.data.errors || []).map((e) => (
            <div key={e}>
              {e}: {error.response.data.errors[e][0]}
            </div>
          ))
        : error.message
    alertToast({
      message,
      type: 'danger',
      duration: 5000,
    })
  },
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...defaultResponse,
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      ...defaultResponse,
    },
  },
})

export const useGetFetchQuery = (name) => {
  return queryClient.getQueryData(name)
}
