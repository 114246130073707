import React from 'react'
import NumberFormat from 'react-number-format'

const Total = (props) => {
    const { total } = props
    return (
        <div className="pagination-total">
            Total <NumberFormat displayType='text' value={total} thousandSeparator /> Items
        </div>
    )
}

export default Total
