import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {en} from './lang/en.js'
import {de} from './lang/de.js'
import {hr} from './lang/hr.js'
import appConfig from 'configs/app.config'

const resources = {
    en: {
        translation: en,
    },
    de: {
        translation: de,
    },
    hr: {
        translation: hr,
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    de: () => import('dayjs/locale/de'),
    hr: () => import('dayjs/locale/hr'),
}

export default i18n
