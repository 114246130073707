import ApiService from './ApiService'
import {  } from 'react-query'
import appConfig from '../configs/app.config'

export const apiSignIn = ({ email, password }) => ApiService.fetchData({
  url: `${appConfig.apiRoot}/Security/auth/Login?email=${email}&password=${password}`,
  method: 'post'
})


export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: '/sign-up',
    method: 'post',
    data
  })
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: '/forgot-password',
    method: 'post',
    data
  })
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: '/reset-password',
    method: 'post',
    data
  })
}

